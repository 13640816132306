var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":'600px'},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("mdi-close")])],1)],1),_c('v-row',[_c('v-col',[_c('div',[_c('h3',{staticClass:"text-center"},[_vm._v("支出")]),_c('div',[_c('v-data-table',{attrs:{"headers":[
                { text: '類別', value: 'category' },
                { text: '總額', value: 'amount' },
              ],"items":_vm.expenseSum,"mobile-breakpoint":0,"item-key":"index","hide-default-footer":"","hide-default-header":""}}),_c('v-divider'),_c('div',{staticClass:"pa-4 font-weight-medium text-right"},[_vm._v(" 總和："+_vm._s(_vm.formatPrice(_vm._.sumBy(_vm.expenseSum, "amount")))+" ")])],1)])]),_c('v-col',[_c('div',[_c('h3',{staticClass:"text-center"},[_vm._v("收入")]),_c('div',[_c('v-data-table',{attrs:{"headers":[
                { text: '類別', value: 'category' },
                { text: '總額', value: 'amount' },
              ],"items":_vm.incomeSum,"mobile-breakpoint":0,"item-key":"index","hide-default-footer":"","hide-default-header":""}}),_c('v-divider'),_c('div',{staticClass:"pa-4 font-weight-medium text-right"},[_vm._v(" 總和："+_vm._s(_vm.formatPrice(_vm._.sumBy(_vm.incomeSum, "amount")))+" ")])],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }