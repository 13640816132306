<template>
  <v-dialog v-model="open" :max-width="'600px'">
    <v-card class="pa-4">
      <v-row justify="end">
        <v-col cols="auto">
          <v-icon @click="$emit('input', false)">mdi-close</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <h3 class="text-center">支出</h3>
            <div>
              <v-data-table
                :headers="[
                  { text: '類別', value: 'category' },
                  { text: '總額', value: 'amount' },
                ]"
                :items="expenseSum"
                :mobile-breakpoint="0"
                item-key="index"
                hide-default-footer
                hide-default-header
              />
              <v-divider></v-divider>
              <div class="pa-4 font-weight-medium text-right">
                總和：{{ formatPrice(_.sumBy(expenseSum, "amount")) }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col>
          <div>
            <h3 class="text-center">收入</h3>
            <div>
              <v-data-table
                :headers="[
                  { text: '類別', value: 'category' },
                  { text: '總額', value: 'amount' },
                ]"
                :items="incomeSum"
                :mobile-breakpoint="0"
                item-key="index"
                hide-default-footer
                hide-default-header
              />
              <v-divider></v-divider>
              <div class="pa-4 font-weight-medium text-right">
                總和：{{ formatPrice(_.sumBy(incomeSum, "amount")) }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import util from "@/mixins/util";
import { paymentType, category } from "@/config/payment_source.js";

export default {
  name: "",
  props: ["value", "filterData"],
  mixins: [util],
  data: () => ({
    paymentType,
    category,
  }),
  computed: {
    _: () => _,
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    expenseSum() {
      return this.category.expense.map((category) => {
        return {
          category,
          amount: this.getSum("支出", category),
        };
      });
    },
    incomeSum() {
      return this.category.income.map((category) => {
        return {
          category,
          amount: this.getSum("收入", category),
        };
      });
    },
  },
  methods: {
    getSum(type, category) {
      return _.sumBy(this.filterData, function (o) {
        if (o.type === type && o.category === category) {
          return o.amount;
        }
        return 0;
      });
    },
  },
  created() {},
};
</script>

<style></style>
