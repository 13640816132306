<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      收支明細

      <v-btn color="primary" @click="openDialog('create')">
        <v-icon>mdi-plus</v-icon>
        新增收支
      </v-btn>
    </h3>
    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
      <v-col cols="auto" align-self="end">
        <v-btn @click="exportExcel" color="secondary" outlined>匯出</v-btn>
      </v-col>
      <v-col cols="auto" align-self="end">
        <v-btn @click="showSum = true" color="secondary">查看總結</v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" sm="4" md="2">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="filterQuery.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              v-bind="attrs"
              v-on="on"
              label="日期區間"
              outlined
              readonly
              dense
              hide-details
              clearable
              class="dates-input"
              @click:clear="filterQuery.date = []"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterQuery.date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(filterQuery.date)"
            >
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-select
          v-model="filterQuery.branch"
          :items="branchOptions"
          label="店點"
          outlined
          dense
          hide-details
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-autocomplete
          v-model="filterQuery.type"
          :items="['支出', '收入']"
          label="收入或支出"
          outlined
          dense
          hide-details
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-select
          v-model="filterQuery.category"
          :items="categoryOptions"
          label="種類"
          outlined
          dense
          hide-details
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-autocomplete
          v-model="filterQuery.source"
          :items="sourceOptions"
          label="來源"
          outlined
          dense
          hide-details
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-text-field
          label="關鍵字"
          placeholder="關鍵字"
          v-model.trim="filterQuery.keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
        >
          <template v-slot:item.amount="{ item }">
            {{ formatPrice(item.amount) || "---" }}
          </template>
          <template v-slot:item.action="{ item }">
            <div class="my-1">
              <v-btn
                class="my-1 mx-2"
                color="primary"
                @click="openDialog('edit', item)"
              >
                編輯
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogCreatePayment
      v-if="dialog.open"
      v-model="dialog.open"
      :dialog="dialog"
      :payment="payload"
      @load="load"
    />
    <DialogPaymentSum
      v-if="showSum"
      v-model="showSum"
      :filterData="filterData"
    />
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";
import {
  translateDataKey,
  exportToExcel,
  reorderObjectKey,
} from "@/utils/exportExcel.js";
import DialogCreatePayment from "@/components/dashboard/payment/DialogCreatePayment.vue";
import DialogPaymentSum from "@/components/dashboard/payment/DialogPaymentSum.vue";

export default {
  name: "Payment",
  mixins: [util],
  components: { DialogCreatePayment, DialogPaymentSum },
  data: () => ({
    payments: [],
    payload: {
      name: "",
      amount: null,
      type: null,
      category: null,
      source: null,
    },
    showSum: false,
    dialog: { type: "create", open: false },
    menu: false,
    filterQuery: {
      date: [],
      keyword: "",
      source: "",
      category: "",
      branch: "",
    },
    branchOptions: [],
    sourceOptions: [],
    categoryOptions: [],

    headers: [
      {
        text: "日期",
        value: "date",
        align: "center",
        sort: (a, b) => {
          if (dayjs(a.date).isAfter(dayjs(b.date))) {
            return -1;
          }
          if (dayjs(a.date).isBefore(dayjs(b.date))) {
            return 1;
          }
          return 0;
        },
      },
      {
        text: "店點",
        value: "branch",
        align: "center",
        sortable: false,
      },
      {
        text: "收支",
        value: "type",
        align: "center",
        sortable: false,
      },
      {
        text: "名目",
        value: "name",
        align: "left",
        sortable: false,
      },
      {
        text: "種類",
        value: "category",
        align: "left",
        sortable: false,
      },
      {
        text: "來源",
        value: "source",
        align: "left",
        sortable: false,
      },
      {
        text: "建立時間",
        value: "createdAt",
        align: "left",
        sortable: true,
        sort: (a, b) => {
          if (dayjs(a.date).isAfter(dayjs(b.date))) {
            return -1;
          }
          if (dayjs(a.date).isBefore(dayjs(b.date))) {
            return 1;
          }
          return 0;
        },
      },
      {
        text: "金額",
        value: "amount",
        align: "right",
        sortable: false,
      },
      {
        text: "操作",
        value: "action",
        align: "center",
        sortable: false,
      },
    ],
  }),
  computed: {
    _: () => _,
    formattedDate() {
      const date = this.filterQuery.date;
      return date[0] && date[1]
        ? dayjs(date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(date[1]).format("YYYY-MM-DD")
        : "";
    },
    filterData() {
      let data = this.payments.sort((a, b) => {
        if (dayjs(a.date).isAfter(dayjs(b.date))) {
          return -1;
        }
        if (dayjs(a.date).isBefore(dayjs(b.date))) {
          return 1;
        }
        return 0;
      });
      const { keyword, date, source, type, branch, category } =
        this.filterQuery;

      data = data.map((item) => {
        return {
          ...item,
          date: dayjs(item.date).format("YYYY/MM/DD"),
        };
      });

      if (date.length === 2) {
        data = data.filter((item) =>
          this.isBetween({
            date: item.date,
            start: date[0],
            end: date[1],
          })
        );
      }
      if (source) {
        data = data.filter((payment) => payment.source === source);
      }
      if (type) {
        data = data.filter((payment) => payment.type === type);
      }
      if (branch) {
        data = data.filter((payment) => payment.branch === branch);
      }
      if (category) {
        data = data.filter((payment) => payment.category === category);
      }
      if (keyword) {
        data = data.filter((payment) => payment.name?.includes(keyword));
      }

      return data;
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.$vloading.show();
      try {
        // const { data } = await this.axios.get(`/payment`);

        let data = [];
        if (this.$store.state.user.角色 === "店長") {
          //店長支援友愛，但僅能開放友愛權限
          const res = await this.axios.get(`/payment`, {
            params: { branch: "友愛" },
          });
          data = res.data;
        } else {
          const res = await this.axios.get(`/payment`);
          data = res.data;
        }

        this.payments = data;
        this.branchOptions = _.uniqBy(data, "branch").map((item) => {
          return {
            value: item.branch,
            text: item.branch + "店",
          };
        });
        this.sourceOptions = _.uniqBy(data, "source").map(
          (item) => item.source
        );
        this.categoryOptions = _.uniqBy(data, "category").map(
          (item) => item.category
        );
      } catch (error) {}
      this.$vloading.hide();
    },
    clear() {
      this.dialog.open = false;
    },
    openDialog(type, payload) {
      this.payload = payload;
      this.dialog.type = type;
      this.dialog.open = true;
    },
    exportExcel() {
      const omitKeys = ["_id", "updatedAt", "createdAt"];
      const keyTranslation = {
        date: "日期",
        branch: "店點",
        type: "收支",
        name: "內容",
        category: "種類",
        source: "來源",
        amount: "金額",
      };
      const desiredKeyOrder = Object.keys(keyTranslation);
      const data = translateDataKey(
        this.filterData.map((item) => reorderObjectKey(item, desiredKeyOrder)),
        keyTranslation,
        omitKeys
      );
      exportToExcel(data, "收支");
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.dates-input {
  min-width: 150px;
}
</style>
