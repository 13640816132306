<template>
  <v-dialog v-model="open" persistent max-width="600">
    <v-card>
      <v-card-title class="mb-3">
        {{ dialog.type === "create" ? "新增支出明細" : "編輯支出明細" }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="payload.date"
                  label="日期"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="payload.date"
                no-title
                scrollable
                locale="zh-tw"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="payload.type"
              :items="['支出', '收入']"
              label="收入 \ 支出"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" v-if="payload.type === '收入'">
            <v-select
              v-model="payload.category"
              :items="category.income"
              label="收入種類"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" v-else>
            <v-select
              v-model="payload.category"
              :items="category.expense"
              label="支出種類"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="payload.branch"
              :items="branches"
              label="店點"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="payload.name"
              label="內容"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="number"
              v-model.number="payload.amount"
              label="金額"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="payload.source"
              :items="source"
              label="來源"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="open = false" class="bt-text-large">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="dialog.type === 'create'"
          text
          color="primary"
          @click="create"
          class="bt-text-large"
        >
          建立
        </v-btn>
        <v-btn
          v-else
          text
          color="primary"
          @click="update"
          class="bt-text-large"
        >
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  paymentSource as source,
  paymentType as type,
  category,
} from "@/config/payment_source.js";

export default {
  name: "DialogCreatePayment",
  props: ["value", "dialog", "payment"],
  data: () => ({
    amount: null,
    payload: {
      name: "",
      type: "",
      amount: null,
      category: null,
      source: "",
      date: "",
    },
    source,
    type,
    category,
    branches: [
      { text: "文賢店", value: "文賢" },
      { text: "崇善店", value: "崇善" },
      { text: "奇美店", value: "奇美" },
      { text: "友愛店", value: "友愛" },
    ],
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    if (this.payment) this.payload = _.cloneDeep(this.payment);
  },
  methods: {
    async create() {
      const hasMissField = Object.values(this.payload).some((value) => !value);

      if (hasMissField) {
        this.$toast.error("請填寫所有欄位");
        return;
      }

      try {
        this.$vloading.show();
        await this.axios.post(`/payment`, {
          ...this.payload,
        });
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || "建立失敗");
      }
      this.$vloading.hide();
    },
    async update() {
      this.$vloading.show();

      try {
        await this.axios.put(`/payment/${this.payment._id}`, {
          ...this.payload,
        });
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || "編輯失敗");
      }
      this.$vloading.hide();
    },
  },
};
</script>
