const paymentSource = [
  "分店櫃檯",
  "老闆",
  "老闆娘",
  "員購",
  "客人",
  "活動",
  "公司",
  "一番賞",
];

const paymentType = ["支出", "收入"];

const category = {
  income: [
    "一番賞",
    "賣貨收入",
    "員購收入",
    "兌換代幣",
    "儲存點數收入",
    "其他收入",
  ],
  expense: [
    "夾換物",
    "活動支出",
    "集點商品",
    "夾抽商品",
    "薪水",
    "兌換代幣",
    "一番賞支出",
    "取消儲存點數",
    "其他",
  ],
};

export { paymentSource, paymentType, category };
